import '../App.css';
import React from 'react'

export default function NewsletterForm() {
    return (
      
        <div>

<div class="ml-embedded" data-form="JaCwZ0"></div>
                  <p className="mt-6 text-sm text-gray-500 text-center">
                    We'll never send spam and you can unsubscribe anytime. <br />See our <a href="/privacy" className="font-medium text-gray-900 underline">
                      Privacy Policy</a> for more info.
                  </p>
                  </div>
    );
}