import '../App.css';
import React from 'react'
import Footer from './Footer'
import Newsletter from './Newsletter-box'
import Features from './Features'
import Hero from './Hero'


export default function Home() {
    return (
        <>
        <Hero />
        <Features />
        <Newsletter />
        <Footer />
        </>
    );
}