import '../App.css';
import React from 'react'

export default function HeaderNav() {
    return (
            <nav className="flex h-9 items-center justify-between" aria-label="Global">
              <div className="flex lg:min-w-0 lg:flex-1 mx-auto sm:items-left" aria-label="Global">
                <a href="/home" className="-m-1.5 p-1.5">
                  <span className="sr-only">Prolific video</span>
                  <img className="sm:h-20 mt-20 sm:mt-2 h-20" src="../logo-letters.svg" alt="" />
                </a>
              </div>
            </nav>
        );
    }