import '../App.css';
import React from 'react'
import {
    DevicePhoneMobileIcon,
    BoltIcon,
    DocumentDuplicateIcon,
    FilmIcon,
    RocketLaunchIcon,
    FireIcon,
  } from '@heroicons/react/24/outline'

const features = [
    {
      name: 'Create with ease',
      description: 'Our platform is designed by creators like you. We empower you to be creative, instead of wasting time on tedious tasks.',
      icon: DevicePhoneMobileIcon,
    },
    {
      name: 'Automate with AI',
      description: 'Adding subtitles or captions manually? Let our platform take care of it all for you automatically.',
      icon: BoltIcon,
    },
    {
      name: 'Customise with templates',
      description: 'Save time and effort with our ready-made templates. Set them up once to use again and again.',
      icon: DocumentDuplicateIcon,
    },
    {
      name: 'Level up with assets',
      description: 'Take your videos to the next level with our library of assets spanning audio, images, and more.',
      icon: FilmIcon,
    },
    {
      name: 'Publish everywhere',
      description: 'Keeping up with several social accounts? Launch your videos how you want and when you want, on any platform.',
      icon: RocketLaunchIcon,
    },
    {
      name: 'More to come!',
      description: "We're always working on new features and improvements to help you create amazing videos.",
      icon: FireIcon,
    },
  ]

export default function Features() {
    return (
        <div className="relative bg-white py-16 sm:py-32 lg:py-40">
      <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8 ">
        <img className="h-16 sm:h20 mx-auto" src="../stars.svg" alt="" />
        <h2 className="text-2xl font-semibold text-teal-600 mb-10">By creators for creators</h2>
        <p className="mt-2 text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Focus on creating, we'll do the rest
        </p>
        <p className="mx-auto mt-7 max-w-prose text-xl text-gray-500 leading-relaxed pb-16">
        Prolific makes it easy to turn any video you make into an engaging Short, Tiktok or Reel. Create, jazz up and distribute your videos to the right platform with minimum effort. 
        </p>
        <div className=" mt-5 sm:mt-20">
          <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6 hover:scale-105 transition ease-in-out delay-150">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8 hover:bg-gradient1 hover:bg-cover hover:bg-left">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-xl bg-teal-500 p-3 shadow-lg">
                        <feature.icon className="h-8 w-8 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-6 text-xl font-semibold leading-8 tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <p className="mt-2 text-base leading-relaxed text-gray-600">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
        );
    }