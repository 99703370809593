import '../App.css';
import React from 'react'
import HeaderNav from './Header-nav'
import NewsletterForm from './Newsletter-form';


export default function Hero() {
    return (
        <div className="isolate bg-white bg-gradient1 bg-cover">
        <div className="px-6 pt-6 lg:px-8">
          <div>
            <HeaderNav />
          </div>
        </div>
        <main>
          <div className="relative px-6 lg:px-8">
            <div className="mx-auto max-w-3xl pt-40 pb-32 sm:pt-48 sm:pb-40">
              <div>
                <div>
                  <h1 className="sm:text-6xl font-bold tracking-tight leading-tight text-center text-4xl">
                  Increase your reach <br />with less effort
                  </h1>
                  <p className="mt-6 text-xl leading-8 text-gray-600 text-center">
                    Using Prolific is the simplest way to turn your videos into engaging clips for social.
                  </p>
                  <div className="mt-8 flex gap-x-4 sm:justify-center">
                <div className="mt-8 mx-auto sm:max-w-lg sm:text-center lg:mx-0 lg:text-left">
                  <p className="text-base font-medium text-gray-900">Sign up to get free access when we launch:</p>
                    <NewsletterForm />
                </div>
              </div>
            </div>
  
              
              </div>
            </div>
          </div>

      </main>
    </div>
        );
    }