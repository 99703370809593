import '../App.css';
import React from 'react'
import NewsletterForm from './Newsletter-form';

export default function Newsletter() {
    return (
<div className="bg-white">
<div className="mx-auto max-w-7xl px-6 py:20 lg:py-20 lg:px-8">
  <div className="hover:scale-105 transition ease-in-out delay-150 rounded-lg bg-gray-900 bg-gradient1 bg-cover px-6 py-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
    <div className="xl:w-0 xl:flex-1">
      <h2 className="text-3xl text-center sm:text-left font-bold tracking-tight text-gray-900 sm:text-3xl">Let Prolific do the grunt work for you</h2>
      <p className="mt-3 mb-3 max-w-3xl text-l text-center lg:text-left text-gray-700">
        Sign up for free access when we launch:
      </p>
    </div>
      <NewsletterForm />
  </div>
</div>
</div>
    );
}

