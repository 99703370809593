import './App.css';
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import ReactGA from 'react-ga';

const TRACKING_ID = "G-PLQFSG6RQG"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return ( 
  <>
    <div className="wrapper">
                    <BrowserRouter>
                      <Routes>
                        <Route exact path='/home' element={<Home/>} />
                        <Route exact path='/' element={<Home/>} />
                        <Route exact path='/terms' element={<Terms/>} />
                        <Route exact path='/privacy' element={<Privacy/>} />
                      </Routes>
                    </BrowserRouter>
                  </div>
  </>
  )
}

export default App;
